import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useContext, useEffect, useRef, useState } from "react";
import icon1 from "../../assets/images/location-pin.png";
import icon2 from "../../assets/images/telephone.png";
import icon3 from "../../assets/images/email.png";
import Banner from "../../components/Banner/Banner";
import BlockTitle from "../../components/BlockTitle/BlockTitle";
import ContactForm from "../../components/ContactForm/ContactForm";
import "./Contact.scss";
import { useTranslation } from "react-i18next";
import { request } from "../../helpers/api";
import { LoadingContext } from "../../helpers/loadingContext";

const Contact = () => {
    const { t, i18n } = useTranslation();
    const ref = useRef(null);
    const [contactInfo, setContactInfo] = useState({
        content: "",
        blockBannerTitle: "",
        banner: { data: { attributes: { url: "" } } },
    });
    gsap.registerPlugin(ScrollTrigger);
  

    const context = useContext(LoadingContext);
    useEffect(() => {
        context.showLoading();
        request("GET", "contact-info", i18n.language).then((response) => {
            const data = response.data.attributes;
            setContactInfo(data);
            context.hideLoading();
        }).catch(err => {context.showError()});
    }, [i18n.language]);
    return (
        <div className="page contact" ref={ref}>
            <div className="ani" data-scroll>
                <Banner
                    label=""
                    main={contactInfo.blockBannerTitle}
                    banner={contactInfo.banner.data.attributes.url}
                    primary=""
                    isNeedHighlight={true}
                />
            </div>
            <div className="content mt-60 mb-60">
                <div className="content-w size-md">
                    <div className="wrap ">
                        <div className=" bl  title mb-40 ani fadeInUp" data-scroll>
                            <BlockTitle
                                main="“Chúng tôi rất hạnh phúc để hỗ trợ thêm cho bạn.  
                                Hãy gởi yêu cầu cho chúng tôi.”"
                            />
                        </div>

                        <div className="bl form  ani fadeInUp" data-scroll>
                            <div className="block-label"> Điền Thông tin</div>
                            <ContactForm />
                        </div>
                        <div className="bl map  ani fadeInUp" data-scroll>
                            <div className="block-label">Thông tin liên lạc</div>
                            <div className="meta mb-40">
                                <a className="meta-item" target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/MH-building/@10.7526941,106.6794005,17z/data=!3m1!4b1!4m5!3m4!1s0x31752fa5cfec5945:0xd161a90aea9620c0!8m2!3d10.7526941!4d106.6815892">
                                    <div className="icon">
                                        <div className="img">
                                            <img src={icon1} />
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="value">{contactInfo == null ? "" : contactInfo.address}</div>
                                    </div>
                                </a>
                                <a className="meta-item" href={`tel:${contactInfo == null ? "" : contactInfo.phone}`}>
                                    <div className="icon">
                                        <div className="img">
                                            <img src={icon2} />
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="value">{contactInfo == null ? "" : contactInfo.phone}</div>
                                    </div>
                                </a>
                                <a className="meta-item" href={`mailto:${contactInfo == null ? "" : contactInfo.email}`}>
                                    <div className="icon">
                                        <div className="img">
                                            <img src={icon3} />
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="value">{contactInfo == null ? "" : contactInfo.email}</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
