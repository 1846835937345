import { Pagination } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import banner from "../../assets/images/Banner_news.jpg";
import Banner from "../../components/Banner/Banner";
import Card from "../../components/Card/Card";
import "./PostPage.scss";
import { useNavigate } from 'react-router-dom';
import {request} from '../../helpers/api';
import { LoadingContext } from "../../helpers/loadingContext";

const PostPage = () => {
  let { cateID } = useParams();
  const context = useContext(LoadingContext);
  const [chosenCate, setChosenCate] = useState(cateID || 'all');
  const [pagination, setPagingInfo] = useState({
    "page": 1,
    "pageSize": 9,
    "pageCount": 0,
    "total": 0
  });

  const setDefaultData = () => {
    setChosenCate(cateID);
    setPagingInfo({
      "page": 1,
      "pageSize": 9,
      "pageCount": 0,
      "total": 0
    });
  };

  const getCateQuery = () => {
    return chosenCate != null && !isNaN(chosenCate)
      ? `&filters[post_type][id][$eq]=${chosenCate}`
      : "";
  };


  const getPaginationQuery = () => {
    return `&pagination[pageSize]=${pagination.pageSize}&pagination[page]=${pagination.page}`;
  };

  const handlePaginationChange = (event, value) => {
    if (value) {
      setPagingInfo({...pagination, page: value});
    }
  };

  const { i18n, t } = useTranslation();

  const [postType, setPostType] = useState({ attributes: { name: "", banner: {
    data : {
      attributes: {
        url : ""
      }
    }
  } } });

  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  const getPosts = () => {
    request('GET', 'posts', i18n.language, `${getCateQuery()}${getPaginationQuery()}`).then((response) => {
      const arrData = response.data;
      setPagingInfo(response.meta.pagination);
      setCards(arrData);
    });
  };

  useEffect(() => {
    context.showLoading();
    setDefaultData();
    request('GET', `post-types/${cateID}`, i18n.language).then((response) => {
      const data = response.data;
      let objectFound = data;
      if (data.attributes.locale != i18n.language) {
        objectFound = data.attributes.localizations.data.find(x => x.attributes.locale === i18n.language);
        if (objectFound != null) {
          let path = location.pathname.split('/').slice(0, -1).join('/') + `/${objectFound.id}`;
          navigate(path);
        }
      }
      setPostType(objectFound);
      context.hideLoading();
    }).catch(err => {context.showError()});
  }, [i18n.language, cateID]);

  useEffect(() => {
    getPosts();
  }, [pagination.page, chosenCate]);

  return (
    <div className="page news-event list-card">
      <div className="ani" data-scroll>
      <Banner label="" main={postType.attributes.blockBannerTitle} 
      banner={postType.attributes.banner.data != null ? postType.attributes.banner.data.attributes.url : banner} primary="" 
      isNeedHighlight={true} />
      </div>
      <div className="content mt-60 mb-60">
        <div className="content-w size-md">
          <div className="wrap">
            <div className="list ani fadeInUp" data-scroll>
              {cards.map((item) => (
                <div className="item" key={item.id}>
                  <Card
                    id={item.id}
                    title={item.attributes.title}
                    time={item.attributes.createdAt}
                    thumb={
                      item.attributes.cover?.data?.attributes?.formats?.thumbnail?.url
                    }
                    excerpt={item.attributes.shortDescription}
                  />
                </div>
              ))}
            </div>
            {!pagination.total && (
                <div className=" mt-40 block-title">
                  {" "}
                  <label>{t("post.no_post_message")}</label>
                </div>
              )}
            {pagination.pageCount > 1 && (
                <div className=" mt-40 block-title">
                  <Pagination
                    page={pagination.page}
                    onChange={handlePaginationChange}
                    count={pagination.pageCount}
                  />
                </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPage;

