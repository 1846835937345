import React, { useContext, useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import "./Partners.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useTranslation } from 'react-i18next';
import { request } from "../../helpers/api";
import DOMPurify from "dompurify";
import { LoadingContext } from "../../helpers/loadingContext";

const Partners = () => {
    const { t, i18n } = useTranslation();
    const context = useContext(LoadingContext);
    const [partnersDetail, setPartnersDetail] = useState({content:"",blockBannerTitle: "", banner:{data:{attributes:{url:""}} }});
    useEffect(() => {
        context.showLoading();
        request('GET', 'configs', i18n.language, `&filters[code][$eq]=partner`).then((response) => {
           if(response.data != null) {
            const data = response.data[0].attributes;
             setPartnersDetail(data);
           }
            context.hideLoading();
        });
      }, [i18n.language]);

      function createMarkup() {
        let html = "<p></p>";
        if( partnersDetail.content) {
            html = DOMPurify.sanitize(partnersDetail == null ? "" : partnersDetail.content);
        }
        return {
          __html: html
        };
      }

    return (
        <div className="page partners ">
            <div className="ani" data-scroll>
                <Banner label="" main={partnersDetail.blockBannerTitle} banner={partnersDetail.banner.data.attributes.url} primary="" isNeedHighlight={true} />
            </div>
            <div className="content mt-60 mb-60">
                <div className="content-w size-md">
                    <div className="wrap">
                        <div className="mb-40 ani fadeInUp" data-scroll>
                            <PageTitle title={t('partner.title')} />
                        </div>
                        <div className="upload-content  ani fadeInUp" data-scroll dangerouslySetInnerHTML={createMarkup()}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;
