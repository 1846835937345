import { Pagination } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import search from "../../assets/images/search.png";
import Banner from "../../components/Banner/Banner";
import ScheduleItem from "../../components/ScheduleItem/ScheduleItem";
import RegiterCourse from "../../modals/RegiterCourse";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import "./Schedule.scss";
import {
  getQueryCheckRelationFieldHasData,
  multipleRequest,
  request,
  spreadArrayResponse,
} from "../../helpers/api";
import { getColorByIndex } from "../../helpers/color";
import { LoadingContext } from "../../helpers/loadingContext";

const Schedule = () => {
  let { id } = useParams();
  const { t, i18n } = useTranslation();
  const context = useContext(LoadingContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 300 ,
      behavior: "smooth",
    });
  }
  const [modalData, setmodalData] = useState({
    isOpen: false,
    dataDetail: { course_template :{data: {attributes:{name:""} }}}
  });

  const [groupCourses, setGroupCourses] = useState([
    {
      monthIndex: 0,
      monthKey: "",
      monthValue: [
        {
          index: 0,
          key: "",
          bgColor:"",
          value: [
            {
              id: 0,
              attributes: {
                course_template: {
                  data: {
                    attributes: {
                      brochure: { data: { attributes: { url: "" } } },
                      course_type: { data: { attributes: { name: "" } } },
                    },
                  },
                },
                place: { data: { attributes: { name: "" } } },
                tags: { data: [] },
              },
            },
          ],
        },
      ],
    },
  ]);
  const [categories, setCategories] = useState([{
    id: 0,
    attributes: {}
  }]);
  const [places, setPlaces] = useState([{
    id: 0,
    attributes: {}
  }]);
  const [eventTypes, setEventTypes] = useState([{
    id: 0,
    attributes: {}
  }]);
  const [chosenCate, setChosenCate] = useState(id || "all");
  const [chosenCateDetail, setChosenCateDetail] = useState({
    blockBannerTitle: "",
    bannerURL: ""
  });
  const [chosenPlace, setChosenPlace] = useState("");
  const [chosenEvenType, setChosenEvenType] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [pagination, setPagingInfo] = useState({
    page: 1,
    pageSize: 5,
    pageCount: 0,
    total: 0,
  });

  const [scheduleAllDetail, setScheduleAllDetail] = useState({content:"",blockBannerTitle: "", banner:{data:{attributes:{url:""}} }});

  const setCateDetailData = (cateID) => {
    let blockBannerTitle = "";
    let bannerURL = "";
    if (cateID && !isNaN(cateID)) {
      const cateObj = categories.find(({ id }) => id == parseInt(cateID));
      if (cateObj != null) {
        blockBannerTitle = cateObj.attributes.blockBannerTitle?? "";
        if(cateObj.attributes.banner.data != null) {
          bannerURL = cateObj.attributes.banner.data.attributes.url??"";
        }
      }
    }

    setChosenCateDetail({ blockBannerTitle, bannerURL });
  };

  const setDefaultPagingInfo = () => {
    setPagingInfo({
      page: 1,
      pageSize: 5,
      pageCount: 0,
      total: 0,
    });
  }

  const setDefaultData = () => {
    if (id && !isNaN(id)) {
      setChosenCate(id);
    } else {
      setChosenCate("all");
    }
    setDefaultPagingInfo();
    setChosenPlace("");
    setChosenEvenType("");
    setChosenEvenType("");
  };

  const onClickSearch = (e) => {
    getCourseData();
  };

  const onSearchStrChange = (event) => {
    if (event.target) {
      setSearchStr(event.target.value);
    }
    event.preventDefault();
  };

  const getSearchStrQuery = () => {
    return searchStr ? `&filters[course_template][name][$contains]=${searchStr}` : "";
  };

  const getPaginationQuery = () => {
    return `&pagination[pageSize]=${pagination.pageSize}&pagination[page]=${pagination.page}`;
  };

  const getCateQuery = () => {
    return chosenCate && !isNaN(chosenCate)
      ? `&filters[course_template][course_type][id][$eq]=${chosenCate}`
      : "";
  };

  const getPlaceQuery = () => {
    return chosenPlace ? `&filters[place][id][$eq]=${chosenPlace}` : "";
  };

  const getEventTypeQuery = () => {
    return chosenEvenType
      ? `&filters[event_type][id][$eq]=${chosenEvenType}`
      : "";
  };

  const openModal = (attributes) => {
    setmodalData({ isOpen: true, dataDetail: attributes });
  };
  const closeModal = () => {
    setmodalData({ isOpen: false, dataDetail: { course_template :{data: {attributes:{name:""} }}} });
  };
  const ref = useRef(null);

  const getCourseData = () => {
    const course_template_query = getQueryCheckRelationFieldHasData('[course_template]');
    const course_type_query = getQueryCheckRelationFieldHasData('[course_template][course_type]');
    request(
      "GET",
      "courses",
      i18n.language,
      `${getSearchStrQuery()}${getCateQuery()}${getPlaceQuery()}${getEventTypeQuery()}${getPaginationQuery()}&filters[dateStart][$gte]=${
        new Date().toISOString().split("T")[0]
      }${course_template_query}${course_type_query}&sort[0]=dateStart%3Aasc`
    ).then((response) => {
      setPagingInfo(response.meta.pagination);
      const arrData = response.data;
      arrData.forEach((element) => {
        const data = element.attributes;
        const date = new Date(data.dateStart);
        data.day = date.getDate();
        data.month = date.toLocaleString(i18n.language, { month: "long" }); // date.getMonth().toLocaleString(i18n.language);
        data.year = date.getFullYear();
        data.hour = date.getHours();
        data.minute = date.getMinutes();
        data.duration = data.course_template.data.attributes.duration ?? 0;
        data.courseID = element.id ?? 0;
      });

      const groupData = arrData.reduce((group, { id, attributes }) => {
        if (!group[attributes.month]) group[attributes.month] = [];
        group[attributes.month].push({ id, attributes });
        return group;
      }, {});

      let arrDataGrouped = [];

      let index = 1;
      for (const [key, value] of Object.entries(groupData)) {
        arrDataGrouped.push({ monthIndex: index, monthKey: key, monthValue: value });
        index++;
      }

      // group by duration

      arrDataGrouped.forEach((dataGrouped) => {
        const value = dataGrouped.monthValue;
        const groupDataByDuration = value.reduce(
          (group, { id, attributes }) => {
            if (!group[attributes.duration]) group[attributes.duration] = [];
            group[attributes.duration].push({ id, attributes });
            return group;
          },
          {}
        );

        let listDataGroupByDuration = [];

        let indexarr = 1;
        for (const [key, value] of Object.entries(groupDataByDuration)) {
          listDataGroupByDuration.push({
            index: indexarr,
            key,
            value,
            bgColor: getColorByIndex(parseInt(key)),
          });
          indexarr++;
        }

        dataGrouped.monthValue = listDataGroupByDuration;
      });


      // @ts-ignore
      setGroupCourses(arrDataGrouped);
    }).catch(err => {
      context.showError();
    });
  };
  useEffect(() => {
    setCateDetailData(chosenCate);
  }, [categories, chosenCate]);

  useEffect(() => {
    context.showLoading();
    multipleRequest([
      request("GET", "places", i18n.language),
      request("GET", "course-types", i18n.language),
      request("GET", "event-types", i18n.language),
      request("GET", "configs", i18n.language, `&filters[code][$eq]=schedule-all`),
    ])
      .then(
        spreadArrayResponse((obj1, obj2, obj3, obj4) => {
          setPlaces(obj1.data);
          setCategories(obj2.data);
          setEventTypes(obj3.data);
          setScheduleAllDetail(obj4.data[0].attributes);
          context.hideLoading();
        })
      )
      .catch((err) => {
        context.showError();
      });

    setDefaultData();
  }, [i18n.language, id]);

  useEffect(() => {
    getCourseData();
  }, [pagination.page, chosenCate, chosenPlace, chosenEvenType]);

  const defaultActionWhenDropDownChangeValue = () => {
    setDefaultPagingInfo();
    scrollToTop();
  }

  const onCateChange = (event) => {
    if (event.target.value) {
      defaultActionWhenDropDownChangeValue();
      setChosenCate(event.target.value);
    }
  };

  const onPlaceChange = (event) => {
    if (event.target.value) {
      defaultActionWhenDropDownChangeValue();
      setChosenPlace(event.target.value);
    }
  };

  const onEvenTypeChange = (event) => {
    if (event.target.value) {
      defaultActionWhenDropDownChangeValue();
      setChosenEvenType(event.target.value);
    }
  };

  const handlePaginationChange = (event, value) => {
    if (value) {
      scrollToTop();
      setPagingInfo({ ...pagination, page: value });
    }
  };

  return (
    <>
      <RegiterCourse data={modalData} close={closeModal} />

      <div className="page schedule" ref={ref}>
        <div className="ani" data-scroll>

          <Banner
            label=""
            main={
              chosenCateDetail.blockBannerTitle != ""
                ? chosenCateDetail.blockBannerTitle
                : scheduleAllDetail.blockBannerTitle
            }
            banner={chosenCateDetail.bannerURL ? chosenCateDetail.bannerURL : scheduleAllDetail.banner.data.attributes.url}
            primary=""
            isNeedHighlight={true}
          />
        </div>
        <div className="content mt-60 mb-60">
          <div className="content-w size-lg">
            <div className="wrap">
              <div className="filter ani fadeInUp" data-scroll>
                <div className="top">
                  <form
                    className="search"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <input
                      onChange={onSearchStrChange}
                      type="search"
                      placeholder={t("schedule.search_placeholder")??""}
                    />
                    <div className="button" onClick={onClickSearch}>
                      <span>{t("search")}</span>
                      <div className="icon">
                        <div className="img">
                          <img src={search} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="bottom">
                  <div className="item">
                    <div className="select-wrap">
                      <select onChange={onCateChange} value={chosenCate}>
                        <option value="all" disabled>
                          {t("schedule.list_course")}
                        </option>
                        {categories?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.attributes.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="item">
                    <div className="select-wrap">
                      <select onChange={onEvenTypeChange} defaultValue="">
                        <option value="" disabled>
                          {t("schedule.event_type")}
                        </option>
                        {eventTypes?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.attributes.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="item">
                    <div className="select-wrap">
                      <select onChange={onPlaceChange} defaultValue="">
                        <option value="" disabled>
                          {t("schedule.location")}
                        </option>
                        {places?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.attributes.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="list mt-60  ani fadeInUp" data-scroll>
                {groupCourses.map(({ monthIndex, monthKey, monthValue }) => (
                  <div key={monthIndex} className="block">
                    <div className="label">{monthKey}</div>
                    {monthValue.map(({ index, key, value, bgColor }) => (
                      <div key={index} className="block-duration">
                        <div className="label sub">
                          Diễn ra trong <span>{key} ngày</span>
                        </div>
                        {Array.isArray(value) ? value.map((item) => (
                          item.attributes != null && <div className="item" key={item.id}>
                            <ScheduleItem
                              bgColor={bgColor}
                              id= {item.id}
                              attributes={item.attributes}
                              onClick={() => openModal(item.attributes)}
                            />
                          </div>
                        )) : <div></div>}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {!pagination.total && (
                <div className=" mt-40 block-title">
                  {" "}
                  <label>{t("empty_result")}</label>
                </div>
              )}
              {pagination.pageCount > 1 && (
                <div className=" mt-40 block-title">
                  <Pagination
                    page={pagination.page}
                    onChange={handlePaginationChange}
                    count={pagination.pageCount}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;
