import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Menu.scss";
import { useTranslation } from "react-i18next";
import { request } from "../../helpers/api";

export default function Menu({ position, open, closeMenu }) {
  const { t, i18n } = useTranslation();
  const location = useLocation().pathname;
  const [arrMenu2, setArrMenu2] = useState([{ id: 0, path: "", name: "" }]);
  const [arrMenu3, setArrMenu3] = useState([{ id: 0, path: "", name: "" }]);
  const [arrMenu4, setArrMenu4] = useState([{ id: 0, path: "", name: "" }]);
  const [newsAndEventCategory, setNewsAndEventCategory] = useState({
    id: 0,
    attributes: { name: "" },
  });
  const [dropdownM1, setDropdownM1] = useState(false);
  const [dropdownM2, setDropdownM2] = useState(false);
  const [dropdownM3, setDropdownM3] = useState(false);
  const [dropdownM4, setDropdownM4] = useState(false);
  const handleDropdownM1 = () => {
    setDropdownM1(!dropdownM1);
    setDropdownM2(false)
    setDropdownM3(false)
    setDropdownM4(false)
  };
  const handleDropdownM2 = () => {
    setDropdownM2(!dropdownM2);
    setDropdownM1(false)
    setDropdownM3(false)
    setDropdownM4(false)
  };
  const handleDropdownM3 = () => {
    setDropdownM3(!dropdownM3);
    setDropdownM2(false)
    setDropdownM1(false)
    setDropdownM4(false)
  };
  const handleDropdownM4 = () => {
    setDropdownM4(!dropdownM4);
    setDropdownM2(false)
    setDropdownM3(false)
    setDropdownM1(false)
  };


  useEffect(() => {
    request("GET", "course-types", i18n.language, "&sort[0]=id%3Aasc").then(
      (response) => {
        const arrData = response.data;
        let arrMenu = [
          { id: 0, path: `/schedule/all`, name: "", description: "" },
        ];
        arrData.forEach((element) => {
          arrMenu.push({
            id: element.id,
            path: `/schedule/${element.id}`,
            name: element.attributes.blockMenuNameTitle,
            description: element.attributes.description,
          });
        });

        setArrMenu3(arrMenu);
      }
    );

    request("GET", "solution-systems", i18n.language, "&sort[0]=id%3Aasc").then(
        (response) => {
          const arrData = response.data;
          let arrMenu = [];
          arrData.forEach((element) => {
            arrMenu.push({
              id: element.id,
              path: `/solution-systems/${element.id}`,
              name: element.attributes.name,
              description: element.attributes.description,
            });
          });
  
          setArrMenu2(arrMenu);
        }
      );

    request("GET", "post-types", i18n.language).then((response) => {
      const arrData = response.data;

      const newsAndEvent = arrData.find((x) => !x.attributes.isResearch);
      if (newsAndEvent != null) {
        setNewsAndEventCategory(newsAndEvent);
      }
      const arrDataMain = arrData.filter((x) => x.attributes.isResearch);

      let arrMenu = [{ id: 0, path: `/post`, name: "", description: "" }];
      if (arrDataMain.length > 0) {
        arrDataMain.forEach((element) => {
          arrMenu.push({
            id: element.id,
            path: `/post/${element.id}`,
            name: element.attributes.name,
            description: element.attributes.description,
          });
        });

        arrMenu[0].path = arrMenu[1].path;
      }

      setArrMenu4(arrMenu);
    });
  }, [i18n.language]);
  // @ts-ignore
  const locationFilter = () => {};

  const arrMenu1 = [
    {
      id: 1,
      path: "/about-us",
      name: "nav_top.nav_item_1.sub_2",
      description: "nav_top.nav_item_1.des_2",
    },
    //{ id: 2, path: "/partners", name: "nav_top.nav_item_1.sub_3" },
    {
      id: 3,
      path: "/core-teams",
      name: "nav_top.nav_item_1.sub_4",
      description: "nav_top.nav_item_1.des_4",
    },
    {
      id: 4,
      path: "/solutions",
      name: "nav_top.nav_item_1.sub_5",
      description: "nav_top.nav_item_1.des_5",
    },
  ];


  const isActiveMenu = (arrMenu) => {
    const arrPath = arrMenu.map((e) => e.path);
    return arrPath.indexOf(location) > -1;
  };

  return (
    <div className={`menu ${position} ${open}`}>
      <div className="wrapper">
        <nav>
          <ul>
            <li className="has-dropdown">
              <Link
                to={`.${arrMenu1[0].path}`}
                className={`lv1  ${isActiveMenu(arrMenu1) ? "active" : ""}`}
                onClick={() => handleDropdownM1()}
              >
                {t("nav_top.nav_item_1.sub_1")}
              </Link>
              <div className={`dropdown ${dropdownM1 ? "active" : ""}`}>
                <ul>
                  {arrMenu1.map((item) => (
                    <li key={item.id}>
                      {" "}
                      <Link
                        to={`.${item.path}`}
                        onClick={closeMenu}
                        className={`lv1 ${
                          location == item.path ? "active" : ""
                        }`}
                      >
                        <span className="main">{t(item.name)}</span>
                        <div className="description">{t(item.description)}</div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li className="has-dropdown">
              <Link
                   to={`.${arrMenu2[0].path}`}
                className={`lv1  ${isActiveMenu(arrMenu2) ? "active" : ""}`}
                onClick={() => handleDropdownM2()}
              >
                {t("nav_top.nav_item_2.sub_1")}
              </Link>
              <div className={`dropdown ${dropdownM2 ? "active" : ""}`}>
                <ul>
                  {arrMenu2.map((item) => (
                    <li key={item.id}>
                      {" "}
                      <Link
                        to={`.${item.path}`}
                        onClick={closeMenu}
                        className={`lv1 ${
                          location == item.path ? "active" : ""
                        }`}
                      >
                        <span className="main">
                          <p
                            dangerouslySetInnerHTML={{ __html: item.name }}
                          ></p>
                        </span>
                        <div className="description">{item.description}</div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li className="has-dropdown">
              <Link
                  to={`.${arrMenu3[0].path}`}
                className={`lv1  ${isActiveMenu(arrMenu3) ? "active" : ""}`}
                onClick={() => handleDropdownM3()}

              >
                {t("nav_top.nav_item_3.sub_1")}
              </Link>
              <div className={`dropdown ${dropdownM3 ? "active" : ""}`}>
                <ul>
                  {arrMenu3.map((item) =>
                    item.id > 0 ? (
                      <li key={item.id}>
                        {" "}
                        <Link
                          to={`.${item.path}`}
                          onClick={closeMenu}
                          className={`lv1 ${
                            location == item.path ? "active" : ""
                          }`}
                        >
                          <span className="main">
                            <p
                              dangerouslySetInnerHTML={{ __html: item.name }}
                            ></p>
                          </span>
                          <div className="description">
                            {item.description ?? ""}
                          </div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              </div>
            </li>
            <li className="has-dropdown">
              <Link
              to={`.${arrMenu4[0].path}`}
                className={`lv1  ${isActiveMenu(arrMenu4) ? "active" : ""}`}
                onClick={() => handleDropdownM4()}

              >
                {t("nav_top.nav_item_4.sub_1")}
              </Link>
              <div className={`dropdown ${dropdownM4 ? "active" : ""}`}>
                <ul>
                  {arrMenu4.map((item) =>
                    item.id > 0 ? (
                      <li key={item.id}>
                        {" "}
                        <Link
                          to={`.${item.path}`}
                          onClick={closeMenu}
                          className={`lv1 ${
                            location == item.path ? "active" : ""
                          }`}
                        >
                          <span className="main">{item.name}</span>
                          <div className="description">
                            {item.description ?? ""}
                          </div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              </div>
            </li>
            <li>
              <Link
                to={`./post/${newsAndEventCategory.id}`}
                className="lv1 "
                onClick={closeMenu}
              >
                {newsAndEventCategory.attributes.name}
              </Link>
            </li>
            <li>
              <Link to={"./contact"} className="lv1 " onClick={closeMenu}>
                {t("nav_top.nav_item_6.sub_1")}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
