// import axios from "axios";
import DOMPurify from "dompurify";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import banner from "../../assets/images/Banner_news.jpg";
import icon1 from "../../assets/images/calendar-primary.png";
import zalo from "../../assets/images/zalo.png";
import Banner from "../../components/Banner/Banner";
import PageTitle from "../../components/PageTitle/PageTitle";
import RelatedPost from "../../components/RelatedPost/RelatedPost";
import "./PostDetail.scss";
import { useTranslation } from "react-i18next";
import {request} from '../../helpers/api';
import { LoadingContext } from "../../helpers/loadingContext";
import SocialSharing from "../../components/SocialSharing/SocialSharing";

const PostDetail = () => {
  let { id } = useParams();
  const { t, i18n } = useTranslation();
  const [cardDetail, setCardDetail] = useState(null);
  const [cardRelated, setcardRelated] = useState([]);
  const context = useContext(LoadingContext);

  useEffect(() => {
    context.showLoading();
    request('GET', `posts/${id}`, i18n.language).then((response) => {
      const arrData = response.data.attributes;
      setCardDetail(arrData);
      context.hideLoading();
    }).catch(err => {context.showError()});
  }, [i18n.language ,id]);

  useEffect(() => {
    if(cardDetail){
      request('GET', 'posts', i18n.language, `&filters[post_type][id][$eq]=${cardDetail.post_type.data.id}`).then((response) => {
        const dataRelated = response.data;
        setcardRelated(dataRelated.filter((e) => e.id != parseInt(id)));
      });
    }
  }, [cardDetail]);


  function createMarkup() {
    return {
      __html: DOMPurify.sanitize(cardDetail == null ? "" : cardDetail.content),
    };
  }

  const dateConverted = (date) => {
    return date.toLocaleString(i18n.language, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  return (
    <div className="page news-event-detail">
      <Banner
        label={""}
        main={
          cardDetail == null
            ? ""
            : cardDetail.post_type.data.attributes.blockBannerTitle
        }
        primary=""
        isNeedHighlight={true}
        banner={banner}
      />
      <div className="content mt-60 mb-60">
        <div className="content-w size-sm">
          <div className="wrap">
            <div className="head  mb-60">
              <div className="mb-30">
                <PageTitle title={cardDetail == null ? "" : cardDetail.title} />
              </div>
              <div className="meta">
                <div className="post-info">
                  <div className="icon">
                    <div className="img">
                      <img src={icon1} />
                    </div>
                  </div>
                  <div className="time">
                    {t("post_details.last_edited")}
                    <span>
                      {cardDetail == null
                        ? ""
                        : dateConverted(new Date(cardDetail.createdAt))}
                    </span>
                  </div>
                </div>
                <div className="share">
                  {t("post_details.share")}
                  <div className="social">
                    {/* <div className="icon">
                      <div className="img">
                        <img src={zalo} />
                      </div>
                    </div> */}
                    <SocialSharing/>                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="upload-content"
              dangerouslySetInnerHTML={createMarkup()}
            ></div>
            <div className="mt-60">
              <RelatedPost posts={cardRelated} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetail;
