import React from "react";
import "./Page404.scss";
import banner from "../../assets/images/banner.jpg";
import Banner from "../../components/Banner/Banner";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <div className="page page404">
      <Banner
        label=""
        main={t('404page.cant_found')}
        primary={t('404page.info')}
        banner={banner}
      />
      <div className="content mt-60 mb-60">
        <div className="content-w size-md">
          <div className="wrap">
            <div className="text">{t('404page.not_found')}</div>
            <div className="action mt-40">
              <Link to={"./"}>{t('404page.back_main_page')}</Link>

              {/* <Button text="Về trang chủ" href={"./"} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
