import React, { useContext, useEffect, useState } from "react";
import icon3 from "../../assets/images/s10.png";
import icon1 from "../../assets/images/s8.png";
import icon2 from "../../assets/images/s9.png";

import Banner from "../../components/Banner/Banner";
import Feature from "../../components/Feature/Feature";
import PageTitle from "../../components/PageTitle/PageTitle";
import "./Solutions.scss";

import { useTranslation } from "react-i18next";
import icon from "../../assets/images/checkmark.png";
import { multipleRequest, request, spreadArrayResponse } from "../../helpers/api";
import { LoadingContext } from "../../helpers/loadingContext";

const featureList = [
    {
        id: 1,
        icon: icon1,
        title: "Lãnh Đạo và Quản Lý​",
        description: "Đạt được kết quả doanh nghiệp<br/> thông qua người khác​",
    },
    {
        id: 2,
        icon: icon2,
        title: "Bán Hàng Xuất Sắc​",
        description: "Tối ưu hóa cơ hội bán hàng<br/>  và xây dựng lòng tin<br/>  với khách hàng​​",
    },
    {
        id: 3,
        icon: icon3,
        title: "Dịch Vụ Khách Hàng ​​",
        description: "Tăng trải nghiệm khách hàng <br/>nhằm đạt được sự trung thành<br/> của khách hàng​",
    },
];

const Solutions = () => {
    const { t, i18n } = useTranslation();

    const [solutions, setSolutions] = useState([{ id: 0,
        attributes: {
            name: '', description: '', 
            icon: {
                data: {
                    attributes: { icon: { data : {attributes: {url: ""}}}}}
                }
        }
    }]);

    const [solutionDetail, setSolutionDeatail] = useState({
        content: "",
        blockBannerTitle: "",
        banner: { data: { attributes: { url: "" } } },
    });

    const context = useContext(LoadingContext);

    useEffect(() => {
        context.showLoading();
        multipleRequest([
            request('GET', 'main-solutions', i18n.language, `&sort[0]=id%3Aasc`),
            request('GET', 'configs', i18n.language, `&filters[code][$eq]=solution`)
        ]).then(spreadArrayResponse((obj1, obj2) => {
            setSolutions(obj1.data);
            setSolutionDeatail(obj2.data[0].attributes);
            context.hideLoading();
        })).catch(err => {context.showError()});

      },[i18n.language]);

    const badgeList = [
        {
            id: 1,
            icon: icon,
            label: t("solution.banner.title1"),
        },
        {
            id: 2,
            icon: icon,
            label: t("solution.banner.title2"),
        },
        {
            id: 3,
            icon: icon,
            label: t("solution.banner.title3"),
        },
        {
            id: 4,
            icon: icon,
            label: t("solution.banner.title4"),
        },
        {
            id: 5,
            icon: icon,
            label: t("solution.banner.title5"),
        },
        {
            id: 6,
            icon: icon,
            label: t("solution.banner.title6"),
        },
        {
            id: 7,
            icon: icon,
            label: t("solution.banner.title7"),
        },
        {
            id: 8,
            icon: icon,
            label: t("solution.banner.title8"),
        },
        {
            id: 9,
            icon: icon,
            label: t("solution.banner.title9"),
        },
        {
            id: 10,
            icon: icon,
            label: t("solution.banner.title10"),
        },
    ];

  

    return (
        <div className="page solution ">
            <div className="ani" data-scroll>
                <Banner
                    label=""
                    main={solutionDetail.blockBannerTitle}
                    banner={solutionDetail.banner.data.attributes.url}
                    primary=""
                    isNeedHighlight={true}
                />
            </div>
            <div className="content mt-60 mb-60">
                <div className="content-w size-md">
                    <div className="wrap">
                        <div className=" mb-40 ani fadeInUp" data-scroll>
                            <PageTitle title={t("solution.title")} />
                        </div>
                        <div className="upload-content">
                            <div className="solution-list mt-60 mb-20 ani fadeInUp" data-scroll>
                                {featureList.map((item) => (
                                    <div className="item" key={item.id}>
                                        <Feature icon={item.icon} title={item.title} description={item.description} />
                                    </div>
                                ))}
                            </div>
                            {/* <div className="titlebar fadeInUp ani" data-scroll>
                                <div className="img">
                                    <img src={titleBar} />
                                </div>
                            </div> */}
                            <div className=" mb-40 ani fadeInUp" data-scroll>
                                <PageTitle title={t('solution.title_1')} />
                            </div>
                            <div className="badge-list center">
                                {/* {badgeList.map((item) => (
                                    <div className="item">
                                        <BadgeText label={item.label} icon={item.icon} size="size-md" />
                                    </div>
                                ))} */}
                                {
                                    solutions.map((item) => (
                                        <div key={item.id} className="item fadeInUp ani " data-scroll>
                                            <div className="icon">
                                                <div className="img">
                                                    <img src={`${item.attributes.icon.data.attributes.url}`} />
                                                </div>
                                            </div>
                                            <div className=" meta">
                                                <div className="label">{item.attributes.name}</div>
                                                <div className="description">
                                                    {item.attributes.description}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Solutions;
