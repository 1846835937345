import React from "react";
import NewsSlider from "../NewsSlider/NewsSlider";
import "./RelatedPost.scss";

export default function RelatedPost({ posts }) {
  return (
    <div className="related-post">
      <label>Bài liên quan</label>
      <div className="list">
        <NewsSlider cardList={posts} isRelatedLink={true} />
      </div>
    </div>
  );
}
