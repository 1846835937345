import React, { useContext, useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import DOMPurify from "dompurify";
import "./SolutionSystem.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useTranslation } from 'react-i18next';
import {request} from '../../helpers/api';
import { LoadingContext } from "../../helpers/loadingContext";
import { useNavigate, useParams } from "react-router";
import Button from "../../components/Button/Button";

const SolutionSystem = () => {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(`../schedule/${cateDetail.course_type.data.id}`);
  };
  
    let { id } = useParams();
    const { i18n, t } = useTranslation();
    const [cateDetail, setCateDetail] = useState({name: "", content:"",blockBannerTitle: "", 
    banner:{data:{attributes:{url:""}} },
    course_type: {
      data: {
        id: 0
      }
    }
  
  });
    const context = useContext(LoadingContext);
    
    useEffect(() => {
        context.showLoading();
        request('GET', `solution-systems/${id}`, i18n.language).then((response) => {
            const arrData = response.data.attributes;
            setCateDetail(arrData);
            context.hideLoading();
        }).catch(err => {context.showError()});
      }, [id, i18n.language]);

      function createMarkup() {
        return {
          __html: DOMPurify.sanitize(cateDetail == null ? "" : cateDetail.content),
        };
      }
      
    return (
        <div className="page solution-system">
            <div className="ani" data-scroll>
            <Banner label="" main={cateDetail.blockBannerTitle} banner={cateDetail.banner.data.attributes.url} primary="" isNeedHighlight={true} />
            </div>
            <div className="content mt-60 mb-60">
                <div className="content-w size-md">
                    <div className="wrap">
                        <div className="mb-40 ani fadeInUp" data-scroll>
                            <PageTitle title={cateDetail.name} isDangerousHTML={true} />
                        </div>
                        <div className="upload-content ani fadeInUp" data-scroll>
                            <div dangerouslySetInnerHTML={createMarkup()}></div>
                        </div>
                    </div>
                </div>
                {
                  cateDetail.course_type.data != null && cateDetail.course_type.data.id > 0 &&
                (<div onClick={navigateTo}
                    className=" mt-40 btn-link-to-schedule"
                  >
                    <Button text={t("register_now")} color_main />
                  </div>)
                }

            </div>
        
        </div>
    );
};

export default SolutionSystem;
