import React, { useEffect, useState } from "react";
import "./NavBarTop.scss";
import ic1 from "../../assets/images/ic1.png";
import ic2 from "../../assets/images/ic2.png";
import ic3 from "../../assets/images/ic3.png";
import ic4 from "../../assets/images/ic4.png";
import ic5 from "../../assets/images/ic5.png";
import ic6 from "../../assets/images/ic6.png";
import ic7 from "../../assets/images/ic7.png";
import ic8 from "../../assets/images/ic8.png";
import ic9 from "../../assets/images/ic9.png";
import { useTranslation } from 'react-i18next';
import { request } from "../../helpers/api";

const lngs = {
  // en: { nativeName: 'EN' },
  vi: { nativeName: 'VN' }
}

export default function NavBarTop({ }) {
  const currentLanguageCode = localStorage.getItem('i18nextLng') || 'vi';
  const { t, i18n } = useTranslation();

  const selectLanguage = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
  }

  const [contactInfo, setContactInfo] = useState({
    phone: "",
    email: "",
});

useEffect(() => {
  request("GET", "contact-info", i18n.language).then((response) => {
      const data = response.data.attributes;
      setContactInfo(data);
  });
}, [i18n.language]);

  return (
    <div className="nav-bar-top">
      <div className="content-w size-lg">
        <div className="wrap">
          <div className="block block-left">
            <div className="contact-information">
              <ul>
                <li>
                  <a className="item" href={`tel:${contactInfo == null ? "" : contactInfo.phone}`}>
                    <div className="ic">
                      <div className="img">
                        <img src={ic1} alt="" />
                      </div>
                    </div>
                    <span>{contactInfo == null ? "" : contactInfo.phone}</span>
                  </a>
                </li>
                <li>
                  <a className="item" href={`mailto:${contactInfo == null ? "" : contactInfo.email}`}>
                    <div className="ic">
                      <div className="img">
                        <img src={ic2} alt="" />
                      </div>
                    </div>
                    <span>{contactInfo == null ? "" : contactInfo.email}</span>
                  </a>
                </li>
                <li>
                  <div className="item">
                    <div className="ic">
                      <div className="img">
                        <img src={ic3} alt="" />
                      </div>
                    </div>
                    <div className="select-language">
                      <label>{t('language')} </label>
                      <div className="block-select">
                        <select onChange={selectLanguage} defaultValue={currentLanguageCode}>
                          {Object.keys(lngs).map((lng, index) => (
                            <option value={lng} key={index}>{lngs[lng].nativeName}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="block block-right">
            <div className="social">
              <ul>
                <li>
                  <a className="item" href="#!">
                    <div className="ic">
                      <div className="img">
                        <img src={ic4} alt="" />
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="item" href="#!">
                    <div className="ic">
                      <div className="img">
                        <img src={ic5} alt="" />
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="item" href="#!">
                    <div className="ic">
                      <div className="img">
                        <img src={ic6} alt="" />
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="item" href="#!">
                    <div className="ic">
                      <div className="img">
                        <img src={ic7} alt="" />
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="item" href="#!">
                    <div className="ic">
                      <div className="img">
                        <img src={ic8} alt="" />
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="item" href="#!">
                    <div className="ic">
                      <div className="img">
                        <img src={ic9} alt="" />
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
