import DOMPurify from "dompurify";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import banner from "../../assets/images/banner.jpg";
import cate from "../../assets/images/categories.png";
import clock from "../../assets/images/clock.png";
import location from "../../assets/images/location.png";
import knowledge from "../../assets/images/knowledge.png";
import s1 from "../../assets/images/s1.png";
import tag from "../../assets/images/tag.png";
import Banner from "../../components/Banner/Banner";
import Button from "../../components/Button/Button";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import RegiterCourse from "../../modals/RegiterCourse";
import "./CourseDetail.scss";
import BadgeText from "../../components/BadgeText/BadgeText";
import { useTranslation } from "react-i18next";
import { request } from "../../helpers/api";
import { getColorByIndex } from "../../helpers/color";
import { LoadingContext } from "../../helpers/loadingContext";

const CourseDetail = () => {
  let { id } = useParams();
  const { t, i18n } = useTranslation();
  const context = useContext(LoadingContext);
  const [isLoading, setIsLoading] = useState(true);

  const [modalData, setmodalData] = useState({
    isOpen: false,
    dataDetail: { course_template :{data: {attributes:{name:""} }}}
  });

  const [courseDetail, setCourseDetail] = useState({
    course_template: {
      data: {
        attributes: {
          description: "",
          name : "",
          course_type: { data: { attributes: { name: "" } } },
        }
      }
    },
    minStudent: 0,
    maxStudent: 0,
    bgColor: "",
    day: 0,
    month: "",
    year: 0,
    hour: 0,
    minute: 0,
    dateLearn: "",
    timeLearn: "",
    place: { data: { attributes: { name: "" } } },
    event_type: { data: { attributes: { name: "" } } },
    tags: { data: [] },
  });

  const openModal = (attributes) => {
    setmodalData({ isOpen: true, dataDetail: attributes });
  };
  const closeModal = () => {
    setmodalData({ isOpen: false, dataDetail: { course_template :{data: {attributes:{name:""} }}} });
  };

  const showContentLoading = () => {
    setIsLoading(true);

  }

  const hideContentLoading = () => {
      setIsLoading(false);
  }


  useEffect(() => {
    context.showLoading();
    showContentLoading();
    request("GET", `courses/${id}`, i18n.language).then((response) => {
      const data = response.data.attributes || null;
      if (data != null) {
        const date = new Date(data.dateStart);
        data.day = date.getDate();
        data.month = date.toLocaleString(i18n.language, { month: "long" });
        data.year = date.getFullYear();
        data.hour = date.getHours();
        data.minute = date.getMinutes();
        data.duration = data.course_template.data.attributes.duration ?? 0;
        data.bgColor = getColorByIndex( data.duration );
        data.courseID = response.data.id;
      }
      setCourseDetail(data);
      context.hideLoading();
      hideContentLoading();
    }).catch(err => {context.showError(); hideContentLoading();});
  }, [i18n.language, id]);

  function createMarkup() {
    return {
      __html: DOMPurify.sanitize(
        courseDetail == null ? "" : courseDetail.course_template.data.attributes.description
      ),
    };
  }

  return (
    <>
      <RegiterCourse data={modalData} close={closeModal} />

      <div className="page course-detail">
        <Banner
          label=""
          main={t("course_form.banner.main")}
          banner={banner}
          primary=""
          isNeedHighlight={true}
        />

        <div className="content mt-60 mb-60">
          <div className="content-w size-sm">
           
            <div className="wrap">
            {isLoading? <LoadingSpinner/> :
              <>
                <div className="bl  upload-content">
                  <div className="head mb-60">
                    <div className="page-title ">{courseDetail.course_template.data.attributes.name}</div>
                  </div>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={createMarkup()}
                  ></div>
                </div>
                <div className="bl side-bar">
                  <div className="calendar" style={{ backgroundColor: courseDetail.bgColor }}>
                    <div className="day">{courseDetail.day}</div>
                    <div className="month">{courseDetail.month}</div>
                    <div className="year">{courseDetail.year}</div>
                  </div>
                  <div className="meta">
                    <BadgeText 
                      label= {courseDetail.course_template.data.attributes.course_type?.data?.attributes?.name} 
                      icon={cate} 
                      size="size-sm" />
                    <BadgeText 
                      label={`${t('course_details.start_from')} ${courseDetail.hour || "00"}:${ courseDetail.minute || "00"}`}
                      icon={clock} 
                      size="size-sm" />
                    {/* <BadgeText 
                      label={courseDetail.timeLearn} 
                      icon={clock} 
                      size="size-sm" /> */}
                    <BadgeText 
                      label={`${courseDetail.dateLearn}`} 
                      icon={clock} 
                      size="size-sm" />
                    <BadgeText 
                      label={`${courseDetail.timeLearn}`} 
                      icon={clock} 
                      size="size-sm" />
                    <BadgeText 
                      label={courseDetail.event_type?.data?.attributes?.name} 
                      icon={knowledge} 
                      size="size-sm" />
                    <BadgeText 
                      label={courseDetail.place?.data?.attributes?.name} 
                      icon={location} 
                      size="size-sm" />
                    <BadgeText 
                      label={`${courseDetail.minStudent} - ${courseDetail.maxStudent} ${t('course_details.member')}`} 
                      icon={s1} 
                      size="size-sm" />
                    {/* <BadgeText  isTag
                      label= {courseDetail.tags.data.map(({id, attributes}) => (
                                  <span  key={id}
                                  style={{
                                      color: `${attributes.textColor}`,
                                      backgroundColor: `${attributes.bgColor}`,
                                  }}>{`#${attributes.name}`}</span>
                              ))} 
                      icon={tag} 
                      size="size-sm" /> */}
                  
                  </div>
                  <div
                    className="register mt-20"
                    onClick={() => openModal(courseDetail)}
                  >
                    <Button text={t("register_now")} color_main />
                  </div>
                </div>
              </>
            }
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetail;
